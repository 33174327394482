
@use "../node_modules/simple-line-icons/dist/styles/simple-line-icons" with (
    $simple-line-icon-prefix      : "sli-",
);
@import "../node_modules/themify-icons-sass/themify-icons/_themify-icons.scss";

.p-toast-top-right {
    z-index: 10000 !important;
}

.login-brand {
    background-image: url('/assets/img/brand/brand_402x44.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 44px;
    width: 100%;
}

.login-card {
    background-image: url('/assets/img/brand/bg_langue.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 44px;
    width: 100%;
}

.boolean {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
    display: inline-block;
    margin: auto;
}

.color {
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.p-inputgroup-addon .p-colorpicker-preview  {
    height: 1rem!important;
}

label.req:after {
    content: '*';
    color: red;
    margin-left: 5px;
}

/* You can add global styles to this file, and also import other style files */
:host ::ng-deep {
    .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }
}


@media screen and (max-width: 40rem) {
    :host ::ng-deep {
        .p-datatable {
            &.p-datatable-responsive {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr > td {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: .4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -.4em 1em -.4em -.4rem;
                        font-weight: bold;
                    }

                    &:last-child {
                        border-bottom: 1px solid var(--surface-d);
                    }
                }
            }
        }
    }
}

app-login,
app-forgot-password {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.p-inputgroup {
    p-dropdown {
        display: flex!important;
        flex: 1 1 auto!important;

        .p-dropdown {
            display: flex!important;
            flex: 1 1 auto!important;
        }
    }
    p-inputnumber {
        display: flex!important;
        flex: 1 1 auto!important;
    }
}

.img-preview {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    max-width: 80%;
    max-height: 200px;
}

.ql-audio:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #444;
    content: "\f028";
    height: 18px;
    width: 18px;
    line-height: 18px;
    display: inline-block;
    vertical-align: top;
}

.p-float-label p-calendar {
    flex: 1 1 auto;
}

#app-sidebar-nav-dropdown-6,
#app-sidebar-nav-dropdown-7,
#app-sidebar-nav-dropdown-8,
#app-sidebar-nav-dropdown-9,
#app-sidebar-nav-dropdown-10 {
    display: none;
}

input.p-dropdown-filter {
    width: 100%!important;
}

.p-checkbox-label {
    margin-bottom: 0.25rem!important;
}
