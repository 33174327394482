// stylelint-disable
// Bootstrap overrides

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #e4e5e6 !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-color:                    $gray-300 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  .875rem !default;

// Breadcrumbs

$breadcrumb-bg:                   #fff !default;
$breadcrumb-margin-bottom:        1.5rem !default;
$breadcrumb-border-radius:        0 !default;

// Cards

$card-border-color:               $gray-300 !default;
$card-cap-bg:                     $gray-100 !default;

// Dropdowns

$dropdown-padding-y:              0 !default;
$dropdown-border-color:           $gray-300 !default;
$dropdown-divider-bg:             $gray-200 !default;

// Buttons

$btn-secondary-border:            $gray-300 !default;

// Progress bars

$progress-bg:                     $gray-100 !default;

// Tables

$table-bg-accent:                 $gray-100 !default;
$table-bg-hover:                  $gray-100 !default;

// Forms

$input-group-addon-bg:            $gray-100 !default;
$input-border-color:              $gray-200 !default;
$input-group-addon-border-color:  $gray-200 !default;
